import { useEffect, useState } from 'react'
import { DisneyRankings, DisneyCharacter } from './style/mainStyles'
import { addSpaces } from '../helpers'

const DisneyCharacters = ({ disneyCharacters, noData }) => {
  const [disneyData, setDisneyData] = useState([])

  useEffect(() => {
    const sortedByValue = Object.entries(disneyCharacters).sort((a, b) => {
      return b[1] - a[1]
    })
    const filtered = sortedByValue.filter(character => character[1]) // Filtering out characters with no votes in case there is only one or two responses
    setDisneyData(filtered.slice(0, 3))
  }, [disneyCharacters])

  const adjustHeight = key => {
    switch (key) {
      case 'Mickey':
        return '3.2rem'
      case 'Mulan':
        return '3.7rem'
      case 'Olaf':
        return '3.7rem'
      case 'Simba':
        return '2.5rem'
      case 'Stitch':
        return '3.2rem'
      default:
        return '3.5rem'
    }
  }

  return (
    <div className="h-full w-full flex flex-col">
      <h2>Disney Character Favorites</h2>
      {disneyData.length ? (
        <DisneyRankings>
          {disneyData.map(datapoint => (
            <DisneyCharacter key={datapoint[0]} imgHeight={adjustHeight(datapoint[0])} rotate={datapoint[0] === 'Minnie'}>
              <div className="imgContainer" key={datapoint[0]}>
                <img src={require(`./../assets/${datapoint[0]}.png`)} />
              </div>
              <div className="ml-10 w-full flex items-center justify-between">
                <h3 className="my-6 whitespace-nowrap">{addSpaces(datapoint[0])}</h3>
                <h3>{datapoint[1]}</h3>
              </div>
            </DisneyCharacter>
          ))}
        </DisneyRankings>
      ) : (
        <div className="h-full flex justify-center items-center">
          <h2>Coming soon!</h2>
        </div>
      )}
    </div>
  )
}

export default DisneyCharacters
