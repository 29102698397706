import React from 'react'
import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip, Legend } from 'chart.js'
import { PolarArea } from 'react-chartjs-2'

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend)

const PolarChart = ({ mmColors, title, noData }) => {
  function createRadialGradient(context, c1, c2) {
    const cache = new Map()
    let width = null
    let height = null

    const chartArea = context.chart.chartArea
    if (!chartArea) {
      // This case happens on initial chart load
      return
    }

    const chartWidth = chartArea.right - chartArea.left
    const chartHeight = chartArea.bottom - chartArea.top
    if (width !== chartWidth || height !== chartHeight) {
      cache.clear()
    }
    let gradient = cache.get(c1 + c2)
    if (!gradient) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth
      height = chartHeight
      const centerX = (chartArea.left + chartArea.right) / 2
      const centerY = (chartArea.top + chartArea.bottom) / 2
      const r = Math.min((chartArea.right - chartArea.left) / 2, (chartArea.bottom - chartArea.top) / 2)

      const ctx = context.chart.ctx
      gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, r)
      gradient.addColorStop(0, c1)
      gradient.addColorStop(1, c2)
      cache.set(c1 + c2, gradient)
    }

    return gradient
  }

  const phyColors = [
    ['rgb(3, 108, 155)', 'rgb(0, 170, 205)'], // --color-phyBlue to --color-phyBlueAccent
    ['rgb(37, 160, 114)', 'rgb(37, 227, 145)'], // --color-phyGreen to --color-phyGreenAccent
    ['rgb(104, 43, 136)', 'rgb(94, 101, 190)'], // --color-phyPurple to --color-phyPurpleAccent
  ]

  const data = {
    labels: [`Blue (${mmColors.blue})`, `Green (${mmColors.green})`, `Purple (${mmColors.purple})`],
    datasets: [
      {
        data: noData ? [1, 1, 1] : [mmColors.blue, mmColors.green, mmColors.purple],
        backgroundColor: function (context) {
          if (noData) return 'grey'
          return createRadialGradient(context, ...phyColors[context.dataIndex])
        },
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      scale: {
        display: false,
      },
    },
    grid: {
      display: false,
      drawOnChartArea: false,
      drawTicks: false,
    },
    scales: {
      r: {
        pointLabels: {
          display: true,
          centerPointLabels: true,
          font: {
            size: 18,
          },
        },
      },
    },
  }

  // AW: div is hard-coded height to prevent looping and infinite re-renders; will investigate
  return (
    <div className="h-full w-full flex flex-col items-center">
      <h1>{title}</h1>
      <PolarArea data={data} options={options} />
    </div>
  )
}

export default PolarChart
