import React from 'react'
import { useEffect, useState } from 'react'
import { LogoWrapper, SubmissionBannerContainer } from './style/mainStyles'
import ConfettiExplosion from 'react-confetti-explosion'
import phyLogo from '../assets/phyLogo.png'

const SubmissionBanner = ({ submissionTimer }) => {
  const [confettiTimer, setConfettiTimer] = useState(false)

  useEffect(() => {
    if (submissionTimer) {
      setTimeout(() => {
        setConfettiTimer(true)
      }, 500)
    } else {
      setConfettiTimer(false)
    }
  }, [submissionTimer])

  const confettiProps = {
    force: 0.8,
    duration: 2500,
    particleCount: 250,
    width: 1600,
    colors: [
      'var(--color-phyPurpleAccent)',
      'var(--color-phyPurpleAccent)',
      'var(--color-phyBlue)',
      'var(--color-phyBlueAccent)',
      'var(--color-phyGreen)',
      'var(--color-phyGreenAccent)',
    ],
  }

  return (
    <>
      <SubmissionBannerContainer submissionTimer={submissionTimer}>
        {confettiTimer && <ConfettiExplosion {...confettiProps} />}
        <div className="w-full flex justify-center items-center">
          <LogoWrapper>
            <img src={phyLogo} />
          </LogoWrapper>
          <p className="text-center">Thank you for your submission!</p>
        </div>
        {confettiTimer && <ConfettiExplosion {...confettiProps} />}
      </SubmissionBannerContainer>
    </>
  )
}

export default SubmissionBanner
