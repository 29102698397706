import { useState, useEffect } from 'react'
import db from './firebase-config'
import BarChart from './components/charts/BarChart'
import DonutChart from './components/charts/DonutChart'
import './index.css'
import './globalStyles.css'
import DataBreakdown from './components/DataBreakdown'
import { DashboardBackground, DashboardItem, Gradient } from './components/style/mainStyles'
import KPIContainer from './components/KPIContainer'
import DisneyCharacters from './components/DisneyCharacters'
import PolarChart from './components/charts/PolarChart'
import SubmissionBanner from './components/SubmissionBanner'

// DASHBOARD DISPLAY RESOLUTION
// 1080x1920 9:16

const testOne = [
  {
    mmColor: 'Blue',
    previousAttendee: true,
    returningVisitor: false,
    continent: 'Africa',
    disneyCharacter: 'Mulan',
    occupation: 'Pathologist',
  },
]

const testTwo = [
  {
    mmColor: 'Blue',
    previousAttendee: true,
    returningVisitor: false,
    continent: 'Africa',
    disneyCharacter: 'Mulan',
    occupation: 'Pathologist',
  },
  {
    mmColor: 'Green',
    previousAttendee: false,
    returningVisitor: false,
    continent: 'Oceania',
    disneyCharacter: 'Donald Duck',
    occupation: 'Laboratory Director',
  },
]

const testThree = [
  {
    mmColor: 'Blue',
    previousAttendee: true,
    returningVisitor: false,
    continent: 'Africa',
    disneyCharacter: 'Mulan',
    occupation: 'Pathologist',
  },
  {
    mmColor: 'Green',
    previousAttendee: false,
    returningVisitor: false,
    continent: 'Oceania',
    disneyCharacter: 'Donald Duck',
    occupation: 'Laboratory Director',
  },
  {
    mmColor: 'Purple',
    previousAttendee: true,
    returningVisitor: true,
    continent: 'Africa',
    disneyCharacter: 'Donald Duck',
    occupation: 'Pathologist',
  },
]

const App = () => {
  const [data, setData] = useState([])
  const [submissionTimer, setSubmissionTimer] = useState(false)
  const [mmColors, setMMColors] = useState({ blue: 0, green: 0, purple: 0 })
  const [returningAACC, setReturningAACC] = useState(0)
  const [returningPhytest, setReturningPhytest] = useState(0)
  const [continents, setContinents] = useState({ Africa: 0, Asia: 0, Europe: 0, NorthAmerica: 0, Oceania: 0, SouthAmerica: 0 })
  const [disneyCharacters, setDisneyCharacters] = useState({
    Donald: 0,
    Mickey: 0,
    Minnie: 0,
    Mulan: 0,
    Olaf: 0,
    Simba: 0,
    Stitch: 0,
    TinkerBell: 0,
  })
  const [occupations, setOccupations] = useState({
    GeneralSupervisor: 0,
    LaboratoryDirector: 0,
    LaboratoryTechnician: 0,
    Other: 0,
    Pathologist: 0,
    Researcher: 0,
    Sales: 0,
    TechnicalDirector: 0,
  })

  const colors = [
    'rgb(73, 30, 94)',
    'rgb(105, 44, 135)',
    'rgb(144, 69, 167, 1)',
    'rgb(182, 94, 199)',
    'rgb(168, 123, 207, 1)',
    'rgb(140, 180, 223, 1)',
    'rgb(126, 209, 231)',
    'rgb(76, 145, 189, 1)',
    'rgb(26, 81, 146)',
    'rgb(18, 56, 102, 1)',
  ]

  // Subscribing to M&M data
  useEffect(() => {
    const unsubscribe = db.collection('mmHandouts').onSnapshot(res => {
      res.docChanges().forEach(change => {
        const doc = { ...change.doc.data(), id: change.doc.id }
        console.log(`CHANGE TYPE (M&M HANDOUTS): ${change.type}`)
        console.log(`DOC RECEIVED (M&M HANDOUTS)\n${JSON.stringify(doc, null, 2)}`)
        switch (change.type) {
          case 'added':
            setTimeout(() => {
              setMMColors(prev => {
                return { ...prev, [doc.id]: doc.count }
              })
            }, 7 * 1000)
          case 'removed':
            setTimeout(() => {
              setMMColors(prev => {
                return { ...prev, [doc.id]: doc.count }
              })
            }, 7 * 1000)
          case 'modified':
            setTimeout(() => {
              setMMColors(prev => {
                return { ...prev, [doc.id]: doc.count }
              })
            }, 7 * 1000)
          default:
            console.log(`Unsure what to do with M&M count change type ${change.type}`)
            break
        }
      })
    })
    return unsubscribe
  }, [])

  // Subscribing to survey data
  useEffect(() => {
    const unsubscribe = db.collection('boothVisits2023').onSnapshot(res => {
      res.docChanges().forEach(change => {
        const doc = { ...change.doc.data(), id: change.doc.id }
        console.log(`CHANGE TYPE (SURVEY DATA): ${change.type}`)
        console.log(`DOC RECEIVED (SURVEY DATA)\n${JSON.stringify(doc, null, 2)}`)
        switch (change.type) {
          case 'added':
            const submissionIDs = data.map(submission => submission.id)
            if (!submissionIDs.includes(doc.id)) {
              setTimeout(() => {
                setSubmissionTimer(true)
              }, 4 * 1000)

              setTimeout(() => {
                setData(prev => {
                  return [...prev, doc]
                })
              }, 7 * 1000)
            }
            break
          case 'removed':
            setData(prev => {
              return [...prev.filter(d => d.id !== doc.id)]
            })
            break
          case 'modified':
            setData(prev => {
              prev[prev.findIndex(d => d.id === doc.id)] = doc
              return [...prev]
            })
            break
          default:
            console.log(`Unsure what to do with survey change type ${change.type}`)
            break
        }
      })
    })
    return unsubscribe
  }, [])

  // Updating state for survey data
  const updateState = ({ key, defaultObj }) => {
    let newState = data.reduce((acc, current) => {
      if (current[key] === 'Sales/Marketing') {
        acc.Sales++
        return acc
      }

      acc[current[key].replace(/\s/g, '')]++
      return acc
    }, defaultObj)

    switch (key) {
      case 'continent':
        setContinents(newState)
        break
      case 'disneyCharacter':
        setDisneyCharacters({ ...newState })
        break
      case 'occupation':
        setOccupations(newState)
    }
  }

  useEffect(() => {
    if (data.length) {
      setReturningAACC(data.filter(datapoint => datapoint.previousAttendee).length)
      setReturningPhytest(data.filter(datapoint => datapoint.returningVisitor).length)
      updateState({ key: 'continent', defaultObj: { Africa: 0, Asia: 0, Europe: 0, NorthAmerica: 0, Oceania: 0, SouthAmerica: 0 } })
      updateState({
        key: 'disneyCharacter',
        defaultObj: {
          Donald: 0,
          Mickey: 0,
          Minnie: 0,
          Mulan: 0,
          Olaf: 0,
          Simba: 0,
          Stitch: 0,
          TinkerBell: 0,
        },
      })
      updateState({
        key: 'occupation',
        defaultObj: {
          GeneralSupervisor: 0,
          LaboratoryDirector: 0,
          LaboratoryTechnician: 0,
          Other: 0,
          Pathologist: 0,
          Researcher: 0,
          Sales: 0,
          TechnicalDirector: 0,
        },
      })
    }
  }, [data])

  // Displaying submission modal
  useEffect(() => {
    if (submissionTimer) {
      setTimeout(() => {
        setSubmissionTimer(false)
      }, 3 * 1000)
    }
  }, [submissionTimer])

  return (
    <>
      <SubmissionBanner submissionTimer={submissionTimer} />
      <DashboardBackground>
        <KPIContainer mmColors={mmColors} returningAACC={returningAACC} returningPhytest={returningPhytest} dataLength={data.length} />
        <DashboardItem style={{ height: '27%' }}>
          <PolarChart mmColors={mmColors} title="M&Ms Shared" noData={!mmColors.green && !mmColors.blue && !mmColors.purple} />
        </DashboardItem>
        <div className="w-full flex justify-between align-center" style={{ height: '25%' }}>
          <DashboardItem style={{ width: '47%' }} className="h-full">
            <BarChart continents={continents} title="Visitors From Around the World" noData={data.length === 0} />
          </DashboardItem>
          <DashboardItem style={{ width: '47%' }}>
            <DisneyCharacters disneyCharacters={disneyCharacters} noData={data.length === 0} />
          </DashboardItem>
        </div>
        <DashboardItem style={{ height: '27%' }} className="flex justify-between">
          <DonutChart occupations={occupations} colors={colors} title="Participating Professions" noData={data.length === 0} />
          <DataBreakdown occupations={occupations} colors={colors} />
        </DashboardItem>
      </DashboardBackground>
    </>
  )
}

export default App
