import React from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { addSpaces } from '../../helpers'
import { BarChartLabel } from '../style/mainStyles'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BarChart = ({ title, continents, noData }) => {
  function createLinearGradient(ctx, chartArea, c1, c2) {
    let width, height, gradient

    const chartWidth = chartArea.right - chartArea.left
    const chartHeight = chartArea.bottom - chartArea.top
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth
      height = chartHeight
      gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
      gradient.addColorStop(0, c1)
      gradient.addColorStop(1, c2)
    }

    return gradient
  }

  const data = {
    labels: Object.keys(continents).map(key => addSpaces(key)),
    datasets: [
      {
        backgroundColor: 'rgb(155,220,220)',
        data: noData ? [1, 1, 1, 1, 1, 1] : Object.values(continents),
        backgroundColor: function (context) {
          if (noData) return 'grey'
          const chart = context.chart
          const { ctx, chartArea } = chart
          if (!chartArea) {
            // This case happens on initial chart load
            return
          }
          return createLinearGradient(ctx, chartArea, 'rgb(104, 43, 136)', 'rgb(94, 101, 190)') // var(--color-phyPurple) and var(--color-phyPurpleAccent)
        },
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    maintainAspectRatio: false,
    layout: {
      padding: {
        bottom: 30,
      },
    },
  }

  return (
    <div className="h-full w-full flex flex-col">
      <h2>{title}</h2>
      <div className='h-3/4'>
      <Bar options={options} data={data} />
      </div>
      <div className='flex justify-between items-start px-3'>
        {Object.keys(continents).map((continent, i) => (
          <BarChartLabel key={continent}>
            {addSpaces(continent)}
          </BarChartLabel>
        ))}
      </div>
    </div>
  )
}

export default BarChart
