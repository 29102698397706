import { KPIContainerDiv, KPIDiv } from './style/mainStyles'

const KPIContainer = ({ mmColors, returningAACC, returningPhytest, dataLength }) => {
  const generatePercentage = val => {
    if (dataLength) return Math.round((val / dataLength) * 100)
    return '--'
  }

  return (
    <KPIContainerDiv>
      <KPIDiv style={{ background: 'linear-gradient(90deg, var(--color-phyPurple), var(--color-phyPurpleAccent)' }}>
        <span>{mmColors.blue + mmColors.green + mmColors.purple}</span>
        <h3>Total M&Ms Shared</h3>
      </KPIDiv>
      <KPIDiv style={{ background: 'linear-gradient(90deg, var(--color-phyBlue), var(--color-phyBlueAccent)' }}>
        <span>{generatePercentage(returningAACC)}%</span>
        <h3>Attended AACC Before</h3>
      </KPIDiv>
      <KPIDiv style={{ background: 'linear-gradient(90deg, var(--color-phyGreen), var(--color-phyGreenAccent)' }}>
        <span>{generatePercentage(returningPhytest)}%</span>
        <h3>Visited Phytest Booth Before</h3>
      </KPIDiv>
    </KPIContainerDiv>
  )
}

export default KPIContainer
