export const setCanvasToViewportSize = canvas => {
  const { width, height } = canvas.getBoundingClientRect()

  if (canvas.width !== width || canvas.height !== height) {
    canvas.width = width
    canvas.height = height
    return true
  }
  return false
}

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export const addSpaces = str => {
  if (str === 'Donald') return 'Donald Duck'
  if (str === 'Mickey') return 'Mickey Mouse'
  if (str === 'Minnie') return 'Minnie Mouse'
  return str.replace(/([A-Z])/g, ' $1').trim()
}
