import { addSpaces } from '../helpers'
import { BreakdownContainer, LegendItem, LegendIcon } from './style/mainStyles'

const DataBreakdown = ({ occupations, colors }) => {
  return (
    <BreakdownContainer>
      {Object.entries(occupations).map((entry, i) => {
        return (
          <LegendItem key={`${i + 1}_legend`}>
            <div className="flex items-end">
              <LegendIcon color={colors[i]} />
              <h3>{entry[0] === 'Sales' ? 'Sales/Marketing' : addSpaces(entry[0])}</h3>
            </div>
            <h3>{entry[1]}</h3>
          </LegendItem>
        )
      })}
    </BreakdownContainer>
  )
}

export default DataBreakdown
