import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

const DonutChart = ({ occupations, title, colors, noData }) => {
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    border: {
      display: false,
    },
  }

  const data = {
    datasets: [
      {
        data: noData ? [1] : Object.values(occupations),
        backgroundColor: function (context) {
          if (noData) return 'grey'
          if (context.dataset.data.length) {
            return colors[context.dataIndex]
          }
          return '#000'
        },
        borderWidth: 0,
      },
    ],
  }

  return (
    <div className="w-3/5 h-4/5 flex flex-col items-center">
      <h2>{title}</h2>
      <Doughnut data={data} options={options} />
    </div>
  )
}

export default DonutChart
