import styled, { css } from 'styled-components'

// #region Dashboard Main Containers
export const DashboardBackground = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #eaeaea;
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const DashboardItem = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0.5rem 0.5rem 0.5rem lightgrey;
`
// #endregion

// #region KPI components
export const KPIContainerDiv = styled.div`
  min-height: 130px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const KPIDiv = styled.div`
  height: 100%;
  width: 30%;
  padding: 1rem;
  color: #fff;
  border-radius: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    font-size: 2.5rem;
  }
`
// #endregion

// Bar chart components
export const BarChartLabel = styled.p`
  rotate: -45deg;
  width: 100%;
  text-align: right;
`

// #region Disney characters components
export const DisneyRankings = styled.div`
  width: 100%;
  height: 100%;
  padding: 0rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`

export const DisneyCharacter = styled.div`
  width: 80%;
  margin: 1.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .imgContainer {
    min-height: 4.5rem;
    height: 4.5rem;
    min-width: 4.5rem;
    width: 4.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0.3rem solid var(--color-phyBlue);
    border-radius: 100%;
  }

  img {
    height: ${props => props.imgHeight};
    rotate: ${props => (props.rotate ? '15deg' : 'none')};
  }
`
// #endregion

// #region Data breakdown components

export const BreakdownContainer = styled.div`
  height: 100%;
  width: 40%;
  padding: 3rem 0;
  margin-left: 1rem;
  margin-right: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const LegendItem = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  h3 {
    margin-left: 1rem;
    font-weight: 500;
  }
`

export const LegendIcon = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  background-color: ${props => props.color};
  border-radius: 5rem;
`
// #endregion

export const SubmissionBannerContainer = styled.div`
  height: 6rem;
  width: 80%;
  position: absolute;
  top: 14rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: linear-gradient(90deg, var(--color-phyBlue), var(--color-phyPurple));
  box-shadow: 0.7rem 0.7rem 1rem grey;
  font-size: 2.5rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: margin-top 0.75s;
  margin-top: ${props => (props.submissionTimer ? '0' : '-21rem')};
`

export const LogoWrapper = styled.div`
  height: 4rem;
  aspect-ratio: 1/1;
  margin-right: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 100%;

  img {
    height: 3.5rem;
    aspect-ratio: 1/1;
  }
`

export const Gradient = styled.div`
  height: 100vh;
  width: 100vw;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(75deg, var(--color-phyPurple), var(--color-phyBlueAccent));
  color: #fff;
  line-height: 1.5;
  font-size: 3rem;
`
